<script setup lang="ts">
const { $Parse, $linker } = useNuxtApp()
const landingStore = useLandingStore()

function toSignUp() {
  if ($Parse.User.current()) {
    $linker.push('/main')
    return
  }
  $linker.push('/signup')
}
</script>

<template>
  <section>
    <!--기관용-->
    <div
      v-if="landingStore.type === 'institution'"
      class="inner">
      <div class="text">
        <h1>
          스타트업 지원 업무,<br />
          질링스에서는<br />
          쉽고 간편합니다.
        </h1>
        <p>
          성과 조사, 뉴스 모니터링, 팩트시트<br />
          제작을 한 곳에서 관리할 수 있습니다.
        </p>
        <div class="actions">
          <a
            href="https://forms.gle/cnEmkNxbGYvUb7Dt7"
            target="_blank"
            class="action action--primary">
            문의하기
          </a>
          <button
            class="action"
            @click="$linker.push('/main')">
            둘러보기
          </button>
        </div>
      </div>
      <div class="image">
        <img
          src="~/assets/images/landings/hero-content-institution.webp"
          alt="Illustration" />
      </div>
    </div>

    <!--기업용-->
    <div
      v-else
      class="inner">
      <div class="text">
        <h1>
          스타트업 운영,<br />
          질링스에서는<br />
          쉽고 간편합니다.
        </h1>
        <p>
          성과 보고, 회사 소개 페이지 관리를<br />
          한 곳에서 관리할 수 있습니다.
        </p>
        <div class="actions">
          <button
            class="action action--primary"
            @click="toSignUp">
            무료가입
          </button>
          <button
            class="action"
            @click="$linker.push('/main')">
            둘러보기
          </button>
        </div>
      </div>
      <div class="image">
        <img
          src="~/assets/images/landings/hero-content-company.webp"
          alt="Illustration" />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  height: 100vh;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
  display: flex;
  align-items: center;
  background-color: #222437;
  @media (max-width: 960px) {
    height: auto;
    display: block;
  }
  .inner {
    max-width: 1080px;
    margin: 0 auto;
    padding: 86px 0 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1152px) {
      display: block;
      padding: 64px 24px 100px;
      max-width: 600px;
      .image {
        margin-top: 64px;
      }
    }
    @media (max-width: 768px) {
      padding: 56px 24px 72px;
      .logo {
        display: block;
        margin-bottom: 16px;
      }
      .text {
        h1 {
          font-size: 24px;
          margin-bottom: 16px;
        }
        .actions {
          margin-top: 48px;
        }
      }
      .image {
        margin: 54px -24px 0;
      }
    }
  }
  .text {
    flex-shrink: 0;
    h1 {
      font-size: 32px;
      font-weight: 800;
      line-height: 1.45;
      color: #fff;
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      line-height: 1.7;
      color: #dadaee;
      margin: 0;
    }
    .actions {
      margin-top: 58px;
      display: flex;
      gap: 8px;
      .action {
        width: 88px;
        height: 48px;
        border: none;
        outline: none;
        border-radius: 8px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        text-decoration: none;
        color: #4d4d4d;
        cursor: pointer;
        &.action--primary {
          background-color: #3948d9;
          color: #fff;
        }
      }
    }
  }
  .image {
    img {
      width: 100%;
    }
  }
}
</style>
