<script setup lang="ts">
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'
import TheLogo from '~/components/TheLogo.vue'
import TheLoader from '~/components/TheLoader.vue'

const landingStore = useLandingStore()

const swiper = ref<Swiper | null>(null)
const loading = ref(true)

watch(() => landingStore.type, initCompanies)
onMounted(() => initCompanies())

async function initCompanies() {
  loading.value = true
  await landingStore.getCompanies()
  await nextTick()
  if (swiper.value) {
    swiper.value.destroy()
  }
  initSwiper()
  loading.value = false
  setTimeout(initSwiper, 1000 * 60 * 60) // 1시간에 한번씩 리셋!
}
function initSwiper() {
  swiper.value = new Swiper('section.with-companies .swiper', {
    loop: true,
    autoplay: {
      delay: 1000
    },
    slidesPerView: 13,
    spaceBetween: 24,
    centeredSlides: true,
    simulateTouch: false,
    modules: [Autoplay]
  })
}
</script>

<template>
  <section class="with-companies">
    <div class="inner">
      <div class="head">
        <img
          src="~/assets/images/landings/with-companies.webp"
          alt="질링스" />
        <p>
          스타트업의 성과 조사, 포트폴리오 관리, 뉴스 모니터링, 팩트시트
          관리,<br h-hideable />
          사업 신청서 등 수작업으로 해왔던 업무를 한 곳에서 관리할 수 있는
          서비스입니다.<br />
          업무에 필요한 플러그인을 선택하여 스타트업 지원 업무를<br
            h-hideable />
          효율적으로 관리하고, 업무 시간을 절감하세요.
        </p>
      </div>
      <div class="companies">
        <div class="companies__title">
          <h3><TheLogo />와 함께하고 있는 고객들</h3>
          <div class="line"></div>
        </div>

        <TheLoader
          v-if="loading"
          color="#FFF"
          absolute />
        <div class="swiper">
          <div class="swiper-wrapper">
            <div
              v-for="(c, i) of landingStore.companies"
              :key="c.companyId + i"
              class="swiper-slide">
              <img
                width="56"
                height="auto"
                :src="c.logo"
                :alt="c.name" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  background-color: #514eda;
  .inner {
    max-width: 1328px;
    margin: auto;
    padding: 120px 0;
    text-align: center;
    @media (max-width: 1152px) {
      padding: 100px 0;
    }
  }
  .head {
    @media (max-width: 1152px) {
      padding-left: 24px;
      padding-right: 24px;
      img {
        width: 100%;
        margin-bottom: 24px;
      }
    }
    img {
      margin-bottom: 48px;
    }
    p {
      margin: 0;
      font-size: 21px;
      line-height: 1.7;
      color: #fff;
      word-break: keep-all;
      @media (max-width: 720px) {
        br[h-hideable] {
          display: none;
        }
      }
    }
  }
  .companies {
    height: 150px;
    margin-top: 100px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      margin-top: 64px;
    }
    &::before,
    &::after {
      content: '';
      width: 400px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-image: linear-gradient(
        90deg,
        #514eda 21.38%,
        rgba(81, 78, 218, 0) 100%
      );
      @media (max-width: 1366px) {
        width: 320px;
      }
      @media (max-width: 1152px) {
        width: 220px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    &::after {
      transform: rotate(-180deg);
      left: inherit;
      right: 0;
    }
  }
  .companies__title {
    width: 1328px;
    height: 18px;
    margin-bottom: 52px;
    position: absolute;
    top: 0;
    h3 {
      padding: 0 15px;
      background-color: #514eda;
      display: inline-flex;
      align-items: center;
      color: #fff;
      font-size: 18px;
      line-height: 1;
      position: relative;
      z-index: 1;
      .logo {
        display: inline-block;
        &:deep(svg) {
          width: 104px;
          margin-right: 3px;
          path {
            fill: #fff;
          }
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: rgba(#fff, 0.3);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .swiper {
    flex-shrink: 0;
    width: 1328px;
    height: 80px;
    position: absolute;
    bottom: 0;
    .swiper-slide {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-sizing: border-box;
      overflow: hidden;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 56px;
        margin: 0;
      }
    }
  }
}
</style>
