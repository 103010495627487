<script setup lang="ts">
import TheSEO from '~/components/TheSEO.vue'
import HeroContent from '~/components/landing/HeroContent.vue'
import WithCompanies from '~/components/landing/WithCompanies.vue'
import TheSection from '~/components/landing/TheSection.vue'
import ContactUs from '~/components/landing/ContactUs.vue'

const landingStore = useLandingStore()
</script>

<template>

  <!-- title="랜딩 페이지" -->
  <TheSEO
    url="/"
    title=""
    description="창업부터 성장까지 필요한 모든 것. SaaS, 교육, 컨설팅으로 비즈니스를 가속화하세요."
    keywords="스타트업 성장 엔진"
    image="https://zillinks.s3.ap-northeast-2.amazonaws.com/assets/images/seo/default.webp" />
  <HeroContent />
  <WithCompanies />

  <template v-if="landingStore.type === 'institution'">
    <TheSection href="/plugins/Portfolio">
      <template #title>
        스타트업 포트폴리오<br />
        관리를 자동화하세요.<br />
      </template>
      <template #description>
        투자 혹은 지원한 스타트업의<br />
        포트폴리오를 만들어 원하는 페이지에<br />
        간편하게 임베드할 수 있습니다.<br />
        포트폴리오에 등록된 스타트업의<br />
        뉴스, 지식재산권, 직원 수는<br />
        모두 자동으로 업데이트됩니다.
      </template>
      <template #image>
        <img
          src="~/assets/images/landings/section-institution1.webp"
          alt="Illustration" />
      </template>
    </TheSection>
    <TheSection
      reversed
      href="/plugins/ApplicationForm">
      <template #title>
        기업용 신청서로<br />
        간편하게 관리하세요.
      </template>
      <template #description>
        지원 사업 신청서, 기업용 설문지 등을<br />
        간편하게 만들 수 있습니다.
      </template>
      <template #image>
        <img
          src="~/assets/images/landings/section-institution2.webp"
          alt="Illustration" />
      </template>
    </TheSection>
    <TheSection href="/plugins/Analytics">
      <template #title>
        성과조사 자동화로<br />
        업무 시간을 절감하세요.
      </template>
      <template #description>
        온라인에 분산된 기업정보를<br />
        RPA(로봇 처리 자동화) 방식으로 수집합니다.<br />
        기업의 월별 매출, 수출 등과 같은 비공개 정보는<br />
        해당 기업의 정보 동의를 통해 동의한 기간 동안<br />
        자동으로 업데이트됩니다.
      </template>
      <template #image>
        <img
          src="~/assets/images/landings/section-institution3.webp"
          alt="Illustration" />
      </template>
    </TheSection>
    <TheSection
      reversed
      href="/plugins/InterestCompany">
      <template #title>
        간편하게 팩트시트를<br />
        만들고 공유하세요.
      </template>
      <template #description>
        팩트시트를 간편하게 만들어<br />
        외부 기관으로 공유할 수 있습니다.<br />
        직원 수, 뉴스, 사업자 등과 같은<br />
        기업의 공공 정보를 자동으로<br />
        업데이트하여 관리가 편합니다.
      </template>
      <template #image>
        <img
          src="~/assets/images/landings/section-institution4.webp"
          alt="Illustration" />
      </template>
    </TheSection>
  </template>

  <template v-else>
    <TheSection>
      <template #title>
        내 기업에 대한 모든 정보를<br />
        한 곳에서 관리하세요.
      </template>
      <template #description>
        홈택스, 국민연금, 지식재산권, 언론 보도를<br />
        연동하여 한 번에 볼 수 있습니다.
      </template>
      <template #image>
        <img
          src="~/assets/images/landings/section-company1.webp"
          alt="Illustration" />
      </template>
    </TheSection>
    <TheSection reversed>
      <template #title>
        반복적인 성과 보고를<br />
        한 번에 해결하세요.
      </template>
      <template #description>
        주기적으로 반복했던 성과보고 업무를<br />
        한 번에 해결할 수 있습니다.
      </template>
      <template #image>
        <img
          src="~/assets/images/landings/section-company2.webp"
          alt="Illustration" />
      </template>
    </TheSection>
    <TheSection href="/plugins/CompanyIntro">
      <template #title>
        회사 소개 웹 페이지를<br />
        간편하게 관리하세요.
      </template>
      <template #description>
        코딩없이 회사 소개 웹 페이지를<br />
        간편하게 제작할 수 있습니다.<br />
        뉴스 API를 통해 자사의 언론보도를<br />
        자동으로 업데이트합니다.
      </template>
      <template #image>
        <img
          src="~/assets/images/landings/section-company3.webp"
          alt="Illustration" />
      </template>
    </TheSection>
  </template>

  <ContactUs />
</template>
