<script setup lang="ts">
const { $Parse, $linker } = useNuxtApp()
const landingStore = useLandingStore()

function toSignUp() {
  if ($Parse.User.current()) {
    $linker.push('/main')
    return
  }
  $linker.push('/signup')
}
</script>

<template>
  <section>
    <div class="inner">
      <template v-if="landingStore.type === 'institution'">
        <h2>
          지금 신청하면, 모든 플러그인을<br />
          30일 동안 무료로 이용할 수 있습니다.
        </h2>
        <a
          href="https://forms.gle/cnEmkNxbGYvUb7Dt7"
          target="_blank"
          class="button">
          문의하기
        </a>
      </template>
      <template v-else>
        <h2>
          지금 가입하면 기업 운영에 필요한<br />
          여러 플러그인을 사용하실 수 있습니다.
        </h2>
        <button
          class="button"
          @click="toSignUp">
          가입하기
        </button>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  display: flex;
  justify-content: center;
  text-align: center;
  .inner {
    padding: 76px 24px 76px;
  }
  h2 {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.65;
    color: #fff;
  }
  .button {
    display: inline-block;
    padding: 16px;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: #3948d9;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
    color: #fff;
  }
}
</style>
